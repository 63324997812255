.position{
    position: fixed;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.position img{
    width: 300px;
    height: 300px;
}