.custombox {
    position: fixed;
    overflow-y: auto;
    z-index: 150;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-height: calc(100vh - 90px);
    width: 580px;
    background: #FFFFFF;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding-bottom: 30px;
}

.custombox::-webkit-scrollbar-track {
    border: none;
    margin: 20px 0px;
    background-color: transparent;
}

.custombox::-webkit-scrollbar {
    width: 4px;
    margin-left: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.custombox::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #0F143A;
    border: 1px solid rgba(15, 20, 58, 0.08);
}

.closebtn {
    margin-top: 20px;
    margin-left: 543px;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.customdata {
    display: flex;
    text-align: center;
    flex-direction: column;
}

.customdata_head {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.04em;
    color: #0F143A;
}

.customdata_desc {
    margin-left: calc(50% - 128px);
    width: 256px;
    height: 42px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.64);
    margin-top: 10px;
}

.customForm {
    margin-left: 50px;
    margin-top: 12px;
}

.customForm h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 116%;
    color: rgba(15, 20, 58, 0.8);
}

#inputfield::placeholder {
    color: rgba(52, 52, 52, 0.64);
}

#inputfield {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 36px;
    width: 480px;
    text-decoration: none;
    padding-left: 12px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    border: 1px solid rgba(0, 22, 54, 0.92);
    border-radius: 6px;
    margin-bottom: 18px;
    margin-top: 10px;
}

textarea:focus, #input:focus {
    outline: none;
}

.selectfield {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 36px;
    width: 480px;
    padding-left: 12px;
    align-items: center;
    border: 1px solid rgba(0, 22, 54, 0.92);
    border-radius: 6px;
    margin-bottom: 18px;
    margin-top: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 5px;
}

.button {
    display: flex;
    justify-content: center;
}

.addbttn {
    padding: 8px 40px;
    background: #0F143A;
    border-radius: 6px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-top: 8px;
    border: none;
    text-align: center;
    cursor: pointer;
    margin-left: -50px;
}

@media only screen and (max-width:640px) {
    .custombox {
        width: 320px;
    }

    .closebtn {
        margin-left: 283px;
    }

    .customdata_head {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.04em;
        color: #0F143A;
    }

    .customdata_desc {
        margin-left: calc(50% - 110px);
        width: 220px;
        height: 30px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.04em;
        color: rgba(15, 20, 58, 0.64);
    }

    .customForm {
        margin-left: 30px;
        margin-top: 12px;
    }

    .customForm h3 {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 116%;
        color: rgba(15, 20, 58, 0.8);
    }

    #inputfield::placeholder {
        color: rgba(52, 52, 52, 0.64);
    }

    #inputfield {
        font-size: 12px;
        height: 32px;
        width: 260px;
    }

    .selectfield {
        font-size: 12px;
        height: 32px;
        width: 260px;
        background-position-y: 4px;
    }

    .addbttn {
        margin-left: -30px;
    }
}