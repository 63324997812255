* {
  margin: 0;
  padding: 0;
}

html, body {
  overflow-x: hidden;
  background-color: #FAF7F1;
  scroll-behavior: smooth;
}

.page {
  position: relative;
  min-height: 100vh;
}