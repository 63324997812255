@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600");
@import url('https://fonts.googleapis.com/css2?family=Solway:wght@300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gamja+Flower&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.AboutUs {
    font-family: 'Nunito', sans-serif;
    background-color: #FAF7F1;
    padding-top: 80px;
    justify-content: center;
    text-align: center;
}

.upperpart {
    background: rgba(255, 117, 117, 0.2);
    padding-top: 80px;
}

.upperpart .img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-image: url('https://imagedelivery.net/aKDgfisBqvZNtFE3Wylbbg/c0de8ef7-e0c7-4697-e83a-d81566c71300/public');
}

.heading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 96px;
    line-height: 116%;
    color: #0F143A;
    margin-bottom: 32px;
}

.subheading {
    margin-top: 71px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 116%;
    text-align: center;
    color: #0F143A;
    margin-bottom: 12px;
}

.storycontent {
    display: flex;
    flex-direction: row;
    margin-top: 36px;
    width: 940px;
    height: 540px;
    margin-left: calc(50% - 470px);
    margin-bottom: 56px;
}

.content {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    color: rgba(15, 20, 58, 0.8);
    padding-left: 50px;
    padding-right: 50px;
}

.endcolon {
    margin-top: 512px;
}

.corevalues {
    background: rgba(255, 117, 117, 0.2);
    padding-bottom: 76px;
}

.coreheading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 116%;
    color: #0F143A;
    padding-top: 60px;
}

.coresubheading {
    margin-top: 20px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    text-align: center;
    color: rgba(52, 52, 52, 0.64);
    margin-bottom: 12px;
}

.values {
    display: flex;
    text-align: left;
    margin-top: 54px;
    width: 920px;
    align-items: center;
    margin-left: calc(50% - 460px);
}

.values h1 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 116%;
    color: #0F143A;
    margin-top: 22px;
}

.values p {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    margin-top: 32px;
    color: rgba(15, 20, 58, 0.8);
}

.quality {
    margin-right: 100px;
    justify-content: center;
    text-align: left;
    width: 240px;
}

.delivery {
    margin-right: 100px;
    justify-content: center;
    text-align: left;
    width: 240px;
}

.cost {
    justify-content: center;
    text-align: left;
    width: 240px;
}

.team {
    padding-bottom: 112px;
}

.teamheading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 116%;
    color: #0F143A;
    margin-top: 52px;
    margin-bottom: 52px;
}

.teammembers {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto;
    gap: 60px 100px;
}

.teams {
    position: relative;
    border: 1px solid black;
    height: 280px;
    width: 228px;
}

.lead {
    margin: 10px 0;
    font-family: 'Gamja Flower';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
}

.dot {
    position: absolute;
    top: -8px;
    left: -8px;
    background-color: #FE9B9B;
    height: 16px;
    width: 16px;
    z-index: 2;
    border-radius: 100px;
    border: 1px solid rgb(0, 0, 0);
}

.admin {
    height: 196px;
    width: 194px;
    border: 1px solid black;
    margin-left: 14px
}

.one {
    height: 196px;
    width: 194px;
    margin-left: 14px;
}

.admindesc {
    height: 196px;
    width: 194px;
    border: 1px solid black;
    margin-left: 14px;
    background: linear-gradient(0deg, #FFD7D7, #FFD7D7);
    display: none;
}

.us {
    padding-top: 60px;
}

.admindesc p {
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 12px;
    font-family: 'Gamja Flower';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: #0F143A;
}

.member {
    margin: 9px 0 0 0;
    font-family: 'Gamja Flower';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
}

.one {
    padding: 0;
    margin: 0;
    position: relative;
}

.social {
    position: absolute;
    bottom: 5px;
    left: 70px;
    cursor: pointer;
}

.one:hover .admindesc {
    display: flex;
}

.one:hover .admin {
    display: none;
}

.one:hover .member {
    margin: 10px
}

@media only screen and (min-width:1578px) {
    .upperpart .img {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-image: url('../../images/AboutUs/claps.png');
    }
}

@media only screen and (min-width:2370px) {
    .upperpart .img {
        width: 100%;
        height: 350px;
        background-size: cover;
        background-image: url('../../images/AboutUs/claps.png');
    }
}

@media only screen and (max-width:1220px) {
    .heading {
        font-size: 78px;
    }

    .teammembers {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: auto auto auto;
        gap: 60px 100px;
    }

    .teammembers {
        gap: 60px 72px;
    }

    .teams {
        position: relative;
        border: 1px solid black;
        height: 280px;
        width: 228px;
    }
}

@media only screen and (max-width:1000px) {
    .heading {
        font-size: 60px;
        margin-bottom: 18px;
    }

    .upperpart img {
        height: 144px;
        margin-bottom: -15px;
    }

    .storycontent {
        width: 732px;
        height: 561px;
        margin-left: calc(50% - 366px);
        margin-bottom: 76px;
    }

    .content {
        font-size: 22px;
        padding-left: 26px;
        padding-right: 26px;
    }

    .endcolon {
        margin-top: 533px;
    }

    .corevalues {
        background: rgba(255, 117, 117, 0.2);
        padding-bottom: 76px;
    }

    .values {
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        margin-top: 54px;
        width: 400px;
        align-items: center;
        margin-left: calc(50% - 200px);
    }

    .values h1 {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 116%;
        color: #0F143A;
        margin-top: 22px;
    }

    .values p {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        margin-top: 32px;
        color: rgba(15, 20, 58, 0.8);
    }

    .quality {
        flex-basis: 100%;
        margin-right: 0px;
        justify-content: center;
        text-align: left;
        margin-bottom: 56px;
    }

    .delivery {
        flex-basis: 100%;
        margin-right: 0px;
        justify-content: center;
        text-align: left;
        margin-bottom: 56px;
    }

    .cost {
        flex-basis: 100%;
        justify-content: center;
        text-align: left;
    }

    .teammembers {
        gap: 60px 60px;
    }
}

@media only screen and (max-width:880px) {
    .heading {
        font-size: 48px;
        margin-bottom: 0px;
    }

    .upperpart img {
        height: 144px;
        margin-bottom: -25px;
    }

    .storycontent {
        width: 592px;
        height: 459px;
        margin-left: calc(50% - 296px);
        margin-bottom: 76px;
    }

    .content {
        font-size: 18px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .endcolon {
        margin-top: 441px;
    }

    .corevalues {
        background: rgba(255, 117, 117, 0.2);
        padding-bottom: 76px;
    }

    .values {
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        margin-top: 54px;
        width: 400px;
        align-items: center;
        margin-left: calc(50% - 200px);
    }

    .values h1 {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 116%;
        color: #0F143A;
        margin-top: 22px;
    }

    .values img {
        width: 47px;
        height: 40px;
    }

    .values p {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        margin-top: 16px;
        color: rgba(15, 20, 58, 0.8);
    }

    .quality {
        flex-basis: 100%;
        margin-right: 0px;
        justify-content: center;
        text-align: left;
        margin-bottom: 56px;
    }

    .delivery {
        flex-basis: 100%;
        margin-right: 0px;
        justify-content: center;
        text-align: left;
        margin-bottom: 56px;
    }

    .cost {
        flex-basis: 100%;
        justify-content: center;
        text-align: left;
    }

    .team {
        padding-bottom: 112px;
    }

    .teamheading {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 116%;
        color: #0F143A;
        margin-top: 52px;
        margin-bottom: 52px;
    }

    .teammembers {
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-columns: auto auto;
        gap: 60px 52px;
    }
}

@media only screen and (max-width:640px) {
    .AboutUs {
        padding-top: 40px;
    }

    .heading {
        font-size: 36px;
    }

    .upperpart {
        background: rgba(255, 117, 117, 0.2);
        padding-top: 40px;
    }

    .upperpart img {
        height: 120px;
        margin-bottom: -22px;
    }

    .subheading {
        margin-top: 32px;
        font-size: 24px;
    }

    .ink {
        width: 16px;
        height: 20px;
    }

    .storycontent {
        margin-top: 20px;
        width: 332px;
        height: 342px;
        margin-left: calc(50% - 166px);
        margin-bottom: 32px;
    }

    .startcolon img {
        width: 16px;
        height: 12px;
    }

    .content {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .endcolon {
        margin-top: 325px;
    }

    .endcolon img {
        width: 16px;
        height: 12px;
    }

    .corevalues {
        background: rgba(255, 117, 117, 0.2);
        padding-bottom: 44px;
    }

    .coreheading {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 116%;
        color: #0F143A;
        padding-top: 32px;
    }

    .coresubheading {
        margin-top: 12px;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: rgba(52, 52, 52, 0.64);
        margin-bottom: 28px;
    }

    .values {
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        margin-top: 28px;
        width: 280px;
        align-items: center;
        margin-left: calc(50% - 140px);
    }

    .values h1 {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 116%;
        color: #0F143A;
        margin-top: 22px;
    }

    .values p {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        margin-top: 20px;
        color: rgba(15, 20, 58, 0.8);
    }

    .quality {
        flex-basis: 100%;
        margin-right: 0px;
        justify-content: center;
        text-align: left;
        margin-bottom: 36px;
    }

    .delivery {
        flex-basis: 100%;
        margin-right: 0px;
        justify-content: center;
        text-align: left;
        margin-bottom: 36px;
    }

    .cost {
        flex-basis: 100%;
        justify-content: center;
        text-align: left;
    }

    .teamheading {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 116%;
        color: #0F143A;
        margin-top: 52px;
        margin-bottom: 52px;
    }

    .teammembers {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: auto;
        gap: 60px 100px;
    }
}