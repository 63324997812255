@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600");
@import url('https://fonts.googleapis.com/css2?family=Gamja+Flower&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.features {
  font-family: 'Nunito';
  width: 100%;
  position: relative;
  z-index: 5;
}

.features h1 {
  text-align: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 116%;
  color: #0f143a;
  margin-top: 64px;
}

.higlight {
  text-decoration: underline;
  text-decoration-thickness: 0.15rem;
  text-underline-offset: 0.5rem;
  color: #0F143A;
}

.outerbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgs {
  position: absolute;
  width: 784px;
  height: 740px;
  margin-top: -112px;
  margin-left: -912px;
  z-index: -1;
}

.imgs img {
  width: 100%;
}

.contentbox {
  width: 1200px;
  height: 512px;
  margin-top: 52px;
  background: #3347FF;
  border-radius: 48px;
  display: flex;
  color: white;
}

.feature {
  padding-top: 60px;
  display: inline-flex;
  align-items: center;
}

.feature img {
  width: 54px;
  height: 66px;
}

.feature h2 {
  font-family: 'Nunito';
  float: left;
  font-weight: 700;
  font-size: 28px;
  line-height: 116%;
  padding-bottom: 8px;
  color: white;
}

.feature p {
  float: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 116%;
  width: 360px;
  letter-spacing: 0.02em;
}

.featuretop{
  margin-top: 7px;
}

.featuresleft{
  margin-left: 98px;
}

.featuresright{
  margin-right: 96px;
}

.written {
  padding-left: 40px;
}

.bottom {
  padding-bottom: 67px;
}

.stari {
  margin-top: -450px;
  margin-left: -55px;
}

.line1 {
  padding-top: 40px;
}

.line1 img {
  width: 100%;
}

@media only screen and (max-width: 1401px) {
  .contentbox {
    width: 1120px;
  }

  .featuresleft{
    margin-left: 80px;
  }

  .featuresright{
    margin-right: 52px !important;
  }

  .stari {
    margin-top: -440px;
    margin-left: -68px;
  }

  .imgs {
    margin-left: -916px;
  }
}

@media only screen and (max-width: 1201px) {
  .feature {
    padding-top: 44px;
  }

  .feature h2 {
    font-size: 24px;
  }

  .feature p {
    font-size: 16px;
    width: 280px;
  }

  .contentbox {
    width: 960px;
    height: 440px;
  }

  .featuretop{
    margin-top: 21px;
  }

  .stari {
    margin-top: -366px;
    margin-left: -60px;
  }

  .imgs {
    margin-top: -44px;
    margin-left: -912px;
  }
}

@media only screen and (max-width: 1040px) {
  .feature {
    padding-top: 44px;
  }

  .feature h2 {
    font-size: 24px;
  }

  .feature p {
    font-size: 16px;
    width: 280px;
  }

  .featuresleft{
    margin-left: 48px;
  }

  .contentbox {
    width: 880px;
    height: 420px;
  }

  .featuretop{
    margin-top: 11px;
  }

  .featuresright{
    margin-right: 36px !important;
  }

  .stari {
    margin-top: -346px;
    margin-left: -60px;
  }

  .imgs {
    margin-top: -18px;
    margin-left: -912px;
  }
}

@media only screen and (max-width: 960px) {
  .feature {
    padding-top: 48px;
    padding-bottom: 0px;
  }

  .feature h2 {
    font-size: 24px;
  }

  .feature p {
    font-size: 16px;
    width: 320px;
  }

  .featuresleft{
    margin-left: 61px;
  }

  .featuresright{
    margin-left: 61px;
  }

  .contentbox {
    flex-wrap: wrap;
    width: 536px;
    height: 792px;
  }

  .featuretop{
    margin-top: 6px;
  }

  .bottom {
    padding-bottom: 0px;
  }

  .featuresright{
    margin-right: 0px;
    margin-top: -54px;
  }

  .stari {
    margin-top: -722px;
    margin-left: -60px;
  }

  .imgs {
    margin-top: -388px;
    margin-left: -908px;
  }
}

@media only screen and (max-width: 696px) {
  .features h1 {
    font-size: 20px;
    margin-top: 33px;
  }

  .feature {
    padding-top: 36px;
    padding-bottom: 0px;
  }

  .feature img {
    width: 28px;
    height: 40px;
  }

  .feature h2 {
    font-size: 16px;
  }

  .feature p {
    font-size: 12px;
    width: 180px;
  }

  .written{
    padding-left: 28px;
  }

  .featuresleft{
    margin-left: 44px;
  }

  .featuresright{
    margin-left: 44px;
  }

  .contentbox {
    flex-wrap: wrap;
    width: 320px;
    height: 620px;
  }

  .featuretop{
    margin-top: 6px;
  }

  .bottom {
    padding-bottom: 0px;
  }

  .featuresright{
    margin-right: 0px;
    margin-top: -42px;
  }

  .stari img{
    width:36px;
    height: 42px;
  }

  .stari {
    margin-top: -544px;
    margin-left: -34px;
  }

  .imgs {
    margin-top: -40px;
    margin-left: 150px;
  }

  .imgs img{
    width: 276px;
    height: 260px;
  }

  .line1 img{
    height:12px !important;
    width: 720px;
  }
}