@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&family=Nunito:wght@400;700&display=swap");

.testimonial {
  font-family: "Nunito";
  background: #faf7f1;
  padding-bottom: 56px;
}

.rabbit {
  margin-left: calc(50% - 12px + 266px);
  margin-top: 46px;
}

.testimonial h1 {
  margin-top: -18px;
  font-size: 48px;
  line-height: 116%;
  color: #0f143a;
  font-weight: 700px;
  text-align: center;
}

.arrowT {
  width: 98px;
  height: 124px;
  margin-top: -36px;
  margin-left: calc(50% - 98px / 2 + 303px);
}

.leftdiv {
  float: left;
  margin-top: -348px;
  margin-left: -60px;
}

.leftdiv img {
  width: 358px;
  height: 340px;
}

.rightdiv {
  margin-top: -128px;
  float: right;
}

.rightdiv img {
  width: 284px;
  height: 132px;
}

.middlediv {
  margin-top: 0px;
  width: 640px;
  margin-left: calc(50% - 320px);
}

.speechboxp2 {
  position: relative;
  height: 440px;
  display: flex;
}

.bubble {
  position: absolute;
  width: 640px;
  height: 440px;
  z-index: 1;
}

.writingp2 {
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
}

.writingp2 p {
  font-family: "Nunito Sans";
  font-weight: 600;
  font-size: 24px;
  line-height: 116%;
  text-align: center;
  letter-spacing: 0.016em;
  color: #343434;
}

.writingp2 img {
  margin-top: 32px;
  /* width: 360px;
  height: 6px; */
  margin-bottom: 24px;
}

.writingp2 h2 {
  font-family: "Nunito";
  font-weight: bold;
  font-size: 22px;
  line-height: 116%;
  text-align: center;
  color: #0f143a;
  margin-bottom: 6px;
}

.writingp2 h3 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 136%;
  text-align: center;
  letter-spacing: 0.02em;
  margin-bottom: 14px;
  color: rgba(52, 52, 52, 0.8);
}

.lowerContent {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.leftarrow img {
  margin-right: 28px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.rightarrow img {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 28px;
}

.leftmostpersondiv img {
  width: 60px;
  height: 60px;
  border: 4px solid rgba(15, 20, 58, 0.2);
  border-radius: 50%;
  cursor: pointer;
  margin-right: 60px;
}

.leftpersondiv img {
  width: 60px;
  height: 60px;
  border: 4px solid rgba(15, 20, 58, 0.2);
  border-radius: 50%;
  cursor: pointer;
  margin-right: 60px;
}

.middlepersondiv img {
  width: 84px;
  height: 84px;
  border: 4px solid #142845;
  border-radius: 50%;
  margin-right: 60px;
}

.rightpersondiv img {
  width: 60px;
  height: 60px;
  border: 4px solid rgba(15, 20, 58, 0.2);
  border-radius: 50%;
  cursor: pointer;
}

.rightmostpersondiv img {
  width: 60px;
  height: 60px;
  border: 4px solid rgba(15, 20, 58, 0.2);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 60px;
}


@media only screen and (min-width: 1720px) {
  .leftdiv {
    margin-top: -484px;
    margin-left: -84px;
  }

  .leftdiv img {
    width: 538px;
    height: 510px;
  }

  .rightdiv {
    margin-top: -118px;
  }

  .rightdiv img {
    width: 568px;
    height: 264px;
  }
}

@media only screen and (max-width: 1180px) {
  .testimonial h1 {
    font-size: 40px;
  }

  .rabbit {
    margin-left: calc(50% - 12px + 222px);
    margin-top: 36px;
  }

  .arrowT {
    margin-top: -30px;
    margin-left: calc(50% - 98px / 2 + 251px);
  }

  .leftdiv {
    margin-top: -222px;
    margin-left: -32px;
  }

  .leftdiv img {
    width: 210px;
    height: 200px;
  }

  .rightdiv {
    margin-top: -138px;
    margin-right: -32px;
  }

  .rightdiv img {
    width: 188px;
    height: 88px;
  }

  .middlediv {
    width: 560px;
    margin-left: calc(50% - 280px);
    margin-top: -50px;
  }

  .speechboxp2 {
    position: relative;
    height: 480px !important;
    display: flex;
  }

  .bubble {
    position: absolute;
    width: 560px;
    height: 480px !important;
  }

  .writingp2 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .writingp2 p {
    font-size: 20px;
  }

  .writingp2 h2 {
    font-size: 20px;
  }

  .lowerContent {
    margin-top: -20px;
  }

  .leftmostpersondiv {
    display: none;
  }

  .rightmostpersondiv {
    display: none;
  }
}

@media only screen and (max-width:880px) {
  .rightdiv {
    margin-top: -180px;
    margin-right: -62px;
  }

  .rightdiv img {
    width: 188px;
    height: 88px;
  }

  .writingp2 {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media only screen and (max-width:800px) {
  .writingp2 {
    margin-left: 35px;
    margin-right: 10px;
  }
}

@media only screen and (max-width:720px) {
  .writingp2 {
    margin-left: 40px;
    margin-right: 0px;
  }
}

@media only screen and (max-width:690px) {
  .testimonial {
    padding-bottom: 0px;
  }

  .testimonial h1 {
    font-size: 24px;
    padding-top: 7px;
  }

  .rabbit {
    margin-left: calc(50% + 130px);
    margin-top: 30px;
    width: 8px;
    height: 10px;
  }

  .arrowT {
    width: 38px;
    height: 48px;
    margin-top: -6px;
    margin-left: calc(50% - 38px / 2 + 145px);
  }

  .leftdiv {
    margin-top: -164px;
    margin-left: -20px;
  }

  .leftdiv img {
    width: 126px;
    height: 120px;
  }

  .rightdiv {
    margin-top: -104px;
    margin-right: -13px;
  }

  .rightdiv img {
    width: 94px;
    height: 44px;
  }

  .middlediv {
    width: 320px;
    margin-top: -30px;
    margin-left: calc(50% - 160px);
  }

  .speechboxp2 {
    height: 272px !important;
  }

  .bubble {
    width: 320px;
    height: 272px !important;
  }

  .writingp2 {
    margin-top: -6px;
    margin-left: 20px;
    margin-right: 20px;
    width: 280px;
  }

  .writingp2 p {
    font-size: 12px;
  }

  .writingp2 img {
    width: 240px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .writingp2 h2 {
    font-size: 14px;
    line-height: 100%;
  }

  .writingp2 h3 {
    font-size: 12px;
    line-height: 116%;
    margin: 0px 0px 6px 0px;
  }

  .lowerContent {
    margin-top: -10px;
    margin-bottom: 24px;
  }

  .leftpersondiv img {
    width: 32px;
    height: 32px;
    margin-right: 40px;
    border: 1px solid #474646;
  }

  .middlepersondiv img {
    width: 40px;
    height: 40px;
    margin-right: 40px;
    border: 2px solid #141313;
  }

  .rightpersondiv img {
    width: 32px;
    height: 32px;
    border: 1px solid #474646;
  }

  .leftarrow img {
    margin-right: 24px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .rightarrow img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 24px;
  }
}

@media only screen and (max-width:450px) {
  .writingp2 {
    margin-right: 5px;
    margin-left: 35px;
  }
}

@media only screen and (max-width:400px) {
  .writingp2 {
    margin-right: 0px;
    margin-left: 40px;
  }
}