.overlayCards {
    position: fixed;
    z-index: 150;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 940px;
    background: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding-bottom: 32px;
    /* max-height: 520px; */
}

.closebtn {
    margin-top: 30px;
    margin-left: 900px;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.productdisplay {
    display: flex;
}

.images {
    margin-top: 50px;
    flex-basis: 40%;
    margin-right: 20px;
}

.mainImage {
    margin-right: 20px;
    margin-left: 30px;
}

.otherimages {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
}

.leftarrow {
    cursor: pointer;
    margin-left: -10px;
    margin-right: 10px;
}

.rightarrow {
    margin-left: 10px;
    cursor: pointer;
}

.leftimage {
    border-radius: 12px;
    border: 1px solid rgba(52, 52, 52, 0.64);
    width: 77px;
    height: 78px;
    margin-right: 10px;
}

.activeimage {
    border-radius: 12px;
    border: 1px solid rgba(52, 52, 52, 0.64);
    width: 77px;
    height: 78px;
    margin-right: 10px;
}

.rightimage {
    border-radius: 12px;
    border: 1px solid rgba(52, 52, 52, 0.64);
    width: 77px;
    height: 78px;
}

.content {
    padding-right: 50px;
    flex-basis: 60%;
    margin-top: 42px;
    overflow-y: scroll;
    max-height: calc(100vh - 200px);
}

.content::-webkit-scrollbar-track {
    border: none;
    margin: 0px 0px 0px 0px;
    background-color: transparent;
}

.content::-webkit-scrollbar {
    width: 4px;
    margin-left: -10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.content::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #0F143A;
    border: 1px solid rgba(15, 20, 58, 0.08);
}

.name {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.04em;
    color: #0F143A;
    margin-bottom: 14px;
}

.branding {
    text-transform: uppercase;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.06em;
    color: #474646;
}

/* .colorname {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.04em;
    color: #0F143A;
} */

.available {
    margin-top: 22px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #191818;
    margin-bottom: 8px;
}

.hexcodes {
    display: flex;
    flex-direction: row;
}

.coloredcircles {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin-right: 12px;
}

.select {
    margin-top: 10px;
    border: 1px solid rgba(15, 20, 58, 0.2);
    border-radius: 4px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #0F143A;
    width: 250px;
    height: 28px;
    text-decoration: none;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0px;
}

.quantandcustom{
    margin-top: 22px;
    display: inline-flex;
}

.quantity_heading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #191818;
    margin-bottom: 4px;
}

.quantity_entry {
    display: inline-flex;
    align-items: center;
}

.increase,
.decrease {
    background: rgba(15, 20, 58, 0.08);
    padding: 2px;
    width: 14px;
    height: 14px;
}

.num {
    margin-left: 15px;
    margin-right: 15px;
}

.customisation{
    margin-left: 52px;
}

.customisation_heading{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #191818;
    margin-bottom: 4px;
}

.customisation_entry {
    display: inline-flex;
    align-items: center;
}

.price {
    margin-top: 22px;
}

.price_heading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.8);
    margin-bottom: 6px;
}

.costing {
    display: inline-flex;
    align-items: center;
}

.total {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #0F143A;
}

.cost {
    margin-left: 20px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: rgba(52, 52, 52, 0.64);
}

.addtocartbtn {
    padding: 9px 40px 8px 40px;
    border: 1px solid rgba(15, 20, 58, 0.2);
    border-radius: 6px;
    margin-top: 24px;
    margin-bottom: 30px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: rgba(15, 20, 58, 0.8);
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.addtocartbtn:hover{
    background-color: #0F143A;
    color: #ffffff;
}

.desc_heading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.8);
    margin-bottom: 6px;
}

.description {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: rgba(52, 52, 52, 0.64);
    margin-right: 10px;
}

.subdescription{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: rgba(52, 52, 52, 0.64);
    margin-right: 10px;
    margin-top: 4px;
}

.itemdetails_heading {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.8);
    margin-top: 12px;
}

.details {
    margin-top: 6px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: rgba(52, 52, 52, 0.64);
}

@media only screen and (max-width:1080px) {
    .overlayCards {
        width: 780px;
    }

    .closebtn {
        margin-left: 750px;
    }

    .images {
        margin-top: 50px;
        flex-basis: 40%;
        margin-right: 20px;
    }
    
    .mainImage {
        margin-right: 0px;
        margin-left: 20px;
    }

    .content {
        flex-basis: 60%;
        margin-top: 42px;
        margin-right: 0px;
    }

    .customisation{
        margin-left: 42px;
    }
}

@media only screen and (max-width:840px) {
    .overlayCards {
        width: 560px;
    }

    .closebtn {
        margin-left: 530px;
    }

    .productdisplay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .content {
        text-align: left;
        margin-left: 46px;
        margin-right: 24px;
        flex-basis: 60%;
        margin-top: 42px;
        overflow-y: hidden;
        max-height: fit-content;
    }

    .mainImage img {
        width: 240px !important;
        height: 200px !important;
    }

    .images {
        margin-top: 0px;
        flex-basis: 100% !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    .mainImage {
        margin-right: 0px;
        margin-left: 0px;
    }

    .overlayCardsp{
        overflow: auto;
        max-height: calc(100vh - 100px);
    }
    
    .overlayCardsp::-webkit-scrollbar-track {
        border: none;
        margin: 20px 0px 0px 0px;
        background-color: transparent;
    }
    
    .overlayCardsp::-webkit-scrollbar {
        width: 4px;
        margin-left: -10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    .overlayCardsp::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #0F143A;
        border: 1px solid rgba(15, 20, 58, 0.08);
    }
}

@media only screen and (max-width:640px) {
    .overlayCards {
        width: 320px;
    }

    .closebtn {
        margin-top: 20px;
        margin-left: 285px;
        margin-bottom: 30px;
    }

    .overlayCardsp{
        overflow: auto;
        max-height: 520px;
    }

    .content{
        margin-left: 30px;
        padding-right: 0px;
    }

    .quantandcustom{
        display: flex;
        flex-direction: column;
    }

    .customisation{
        margin-left: 0px;
        margin-top: 22px;
    }
}