@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,600;1,400&family=Nunito:wght@400;600;700&display=swap");

.mpage {
  margin-top: 0px;
  display: flex;
  scroll-behavior: smooth;
}

.view,
.view_desktop {
  display: none;
}

.categorymobile {
  display: none;
}

.crds {
  padding-top: 120px;
}

.crds:first-child {
  padding-top: 120px;
}

.cardsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #faf7f1;
  margin-left: 200px;
  padding-left: 50px;
  padding-right: 100px;
  margin-right: 220px;
  margin-bottom: 100px;
}

.swag {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #0f143a;
  margin-left: 32px;
}

.heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: rgba(15, 20, 58, 0.8);
  margin-top: 20px;
  margin-left: 32px;
}

.subheading {
  font-family: "Nunito";
  font-style: normal;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.04em;
  color: rgba(15, 20, 58, 0.6);
  margin-top: 18px;
  margin-left: 32px;
}

.pRows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pCards {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 40px;
  margin-left: 32px;
  width: 240px;
  height: 310px;
}

.pCards img {
  border-radius: 12px 12px 0px 0px;
}

.pimage {
  width: 240px;
  height: 200px;
  cursor: pointer;
}

.pbranding {
  margin-top: -6px;
  height: 22px;
  align-items: center;
  background-color: #0F143A;
  display: flex;
  justify-content: center;
  text-align: end;
  color: white;
  text-transform: uppercase;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.14em;
}

.pCardsbody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 22, 54, 0.2);
  border-bottom: 0px;
}

.name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #0f143a;
  margin: 10px 0px 4px 12px;
  cursor: pointer;
}

.price {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: rgba(15, 20, 58, 0.8);
  margin-left: 14px;
  margin-bottom: 12px;
  cursor: pointer;
}

.add {
  margin-top: 14px;
  margin-left: 10px;
  margin-right: 14px;
}

.addimg {
  width: 28px;
  height: 28px;
}

.pCardsTBD {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-top: 40px;
  margin-left: 32px;
  width: 240px;
  height: 310px;
}

.pcustombranding {
  margin-top: -6px;
  height: 22px;
  align-items: center;
  background-color: #0F143A;
  display: flex;
  justify-content: center;
  text-align: end;
  color: white;
  text-transform: uppercase;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.14em;
}

.TBD {
  cursor: pointer;
  width: 240px;
  height: 200px;
}

.pCardsbodyTBD {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 22, 54, 0.2);
  border-bottom: 0px;
}

.lTBD {
  justify-content: center;
  align-items: center;
}

.lTBD h5 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: #0f143a;
  margin: 16px 0px 8px 14px;
}

.lTBD p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: rgba(15, 20, 58, 0.8);
  margin: 0px;
  margin-left: 14px;
  margin-bottom: 31px;
}

.rTBD {
  margin-left: 10px;
  margin-top: 14px;
  margin-right: 14px;
}

.rTBD img {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.pCardsCustom {
  background: rgba(15, 20, 58, 0.08);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  width: 240px;
  height: 310px;
  margin-left: 32px;
  cursor: pointer;
}

.pCardsCustom:hover{
  background: rgba(15, 20, 58, 0.21);
}

.upperCustom h3 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #0f143a;
  margin: 42px 34px 16px 34px;
}

.upperCustom p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.04em;
  color: rgba(15, 20, 58, 0.8);
  margin: 0px 34px 0px 34px;
}

.pCardsbodyCustom {
  margin: 36px 96px 51px 96px;
}

.pCardsbodyCustom img {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

@media only screen and (max-width:1600px) {
  .cardsSection {
    padding-left: 35px;
  }
}

@media only screen and (max-width:1440px) {
  .cardsSection {
    width: 100%;
    padding-left: 28px;
  }

  .swag,
  .heading,
  .subheading,
  .pCards,
  .pCardsCustom,
  .pCardsTBD {
    margin-left: 20px;
  }
}

@media only screen and (max-width:1350px) {
  .cardsSection {
    width: 100%;
    padding-left: 15px;
  }
}

@media only screen and (max-width:1225px) {
  .crds {
    padding-top: 100px;
  }

  .crds:first-child {
    padding-top: 240px;
  }

  .cardsSection {
    width: 100%;
    margin-left: 0px;
    padding-left: 40px;
    padding-right: 0px;
    margin-right: 0px;
    padding-right: 60px;
  }

  .cartsystem {
    display: none;
  }

  .view_desktop {
    display: block;
    margin-top: 190px;
    position: fixed;
    right: 0px;
  }

  .bttnnn {
    text-align: center;
    padding: 20px 32px;
    height: 58px;
    background: #0F143A;
    border-radius: 0px 0px 0px 12px;
    border: 1px solid #0F143A;
    color: white;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.016em;
    cursor: pointer;
    margin-top: 0px;
  }

  .cartpopup {
    position: fixed;
    right: 0px;
    top: 250px;
    z-index: 200;
  }
}

@media only screen and (max-width:640px) {
  .swag,
  .heading,
  .subheading,
  .pCards,
  .pCardsCustom,
  .pCardsTBD {
    margin-left: 0px;
  }

  .mpage {
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }

  .cardsSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    background: #faf7f1;
    padding-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    margin-bottom: 100px;
  }

  .crds {
    padding-top: 80px;
  }

  .crds:first-child {
    padding-top: 190px;
  }

  .pRows {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .pCards {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top: 40px;
    margin-right: 0px;
    margin-left: calc(50% - 110px);
    width: 220px;
    height: 212px;
    text-align: center;
  }

  .pimage {
    width: 132px;
    height: 110px;
  }

  .pCardsbody {
    display: flex;
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
    border-top: 1px solid rgba(0, 22, 54, 0.2);
    border-bottom: 0px;
  }

  .name {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: #0f143a;
    margin: 14px 0px 4px 14px;
    cursor: pointer;
  }

  .price {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.8);
    margin-left: 14px;
    margin-bottom: 28px;
    cursor: pointer;
  }

  .pCardsTBD {
    width: 220px;
    height: 212px;
    margin-left: calc(50% - 110px);
    margin-right: 0px;
    text-align: center;
  }

  .TBD {
    width: 132px;
    height: 110px;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
  }

  .pCardsbodyTBD {
    text-align: left;
  }

  .lTBD h5 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.04em;
    color: #0f143a;
    margin: 14px 0px 8px 14px;
  }
  
  .lTBD p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.8);
  }
  
  .rTBD {
    margin-left: 10px;
    margin-top: 14px;
    margin-right: 14px;
  }
  
  .rTBD img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .pCardsCustom {
    width: 220px;
    margin-left: calc(50% - 110px);
    margin-right: 0px;
    height: 212px;
  }

  .upperCustom h3 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.04em;
    color: #0f143a;
    margin: 34px 24px 16px 24px;
  }

  .upperCustom p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.04em;
    color: rgba(15, 20, 58, 0.8);
    margin: 0px 24px 20px 24px;
  }

  .pCardsbodyCustom {
    margin: 0px 96px 36px 96px;
  }

  .pCardsbodyCustom img {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }

  .cartsystem {
    display: none;
  }

  .view_desktop {
    display: none;
  }

  .view {
    display: block;
    bottom: 0px;
    position: fixed;
    width: 100%;
    text-align: center;
    padding: 16px 32px 8px;
    background: #0F143A;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px 12px 0px 0px;
    cursor: pointer;
    height: 70px;
    align-items: center;
    z-index: 2;
  }

  .bttnn {
    text-align: center;
    padding: 0px 0px;
    height: 35px;
    background: transparent;
    border-radius: 0px;
    border: none;
    color: white;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.016em;
  }

  /* .bttnn:hover {
    transition: all 0.3s ease;
    background-color: transparent;
    color: white;
    border: 1px solid #0F143A;
  } */

  .cartpopup {
    width: 100%;
    position: fixed;
    left: 0;
    top: 40px;
    z-index: 20;
  }
}