@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600");
@import url('https://fonts.googleapis.com/css2?family=Gamja+Flower&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.contact {
    padding-top: 190px;
    background: linear-gradient(180deg, rgba(151, 180, 255, 0.4) 23.26%, rgba(255, 255, 255, 0) 100%);
    font-family: 'Nunito', sans-serif;
    justify-content: center;
}

.contact h1 {
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    line-height: 116%;
}

.contact h3 {
    font-weight: 600;
    margin-bottom: 56px;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: 0.04em;
    color: rgba(52, 52, 52, 0.64);
    margin-top: 32px;
    text-align: center;
}

.chat {
    position: absolute;
    z-index: 5;
    left: calc(50% - 230px);
    top: 132px;
}

.man {
    position: absolute;
    z-index: 5;
    left: calc(50% - 546px);
    top: 194px;
}

.letter {
    position: absolute;
    z-index: 5;
    left: calc(50% - 508px);
    top: 434px;
}

.headphones {
    position: absolute;
    z-index: 5;
    left: calc(50% + 320px);
    top: 150px;
}

.mark {
    position: absolute;
    z-index: 5;
    left: calc(50% + 526px);
    top: 278px;
}

.available {
    position: absolute;
    z-index: 5;
    left: calc(50% + 406px);
    top: 418px;
}

.questions {
    display: flex;
    justify-content: center;
}

.faq {
    background-color: #ACC3FF;
    width: 576px;
    height: 240px;
    border-radius: 28px;
    display: inline-flex;
}

.leftfaq {
    font-size: 24px;
    font-weight: 600;
    line-height: 136%;
    margin-top: 46px;
    margin-left: 44px;
}

.faqbutton {
    background-color: #0F143A;
    color: white;
    border: none;
    padding: 14px 32px;
    border-radius: 6px;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.01em;
    margin-top: 28px;
}

.imagefaq {
    margin-right: 40px;
    margin-top: 36px;
}

.imagefaq img {
    width: 100px;
    height: 164px;
}

.lower {
    display: flex;
    justify-content: center;
}

.mainform {
    display: inline-flex;
    background-color: white;
    border-radius: 32px;
    padding: 80px 32px 30px 72px;
    margin-top: 96px;
    margin-bottom: 120px;
    width: 960px;
}

.leftcontent h4 {
    color: #464CDD;
    background-color: rgba(105, 192, 255, 0.14);
    width: 175px;
    text-align: center;
    border-radius: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 28px;
    line-height: 116%;
    font-weight: 700;
    margin-bottom: 36px;
}

.leftcontent h2 {
    line-height: 136%;
    color: #0F143A;
    font-weight: 600;
    font-size: 36px;
}

.downcallandmail{
    display: none;
}

.leftcontent .call {
    margin-top: 52px;
}

.callhead {
    padding: 2px 0px 0px;
    display: inline-flex;
}

.callhead p {
    font-size: 24px;
    font-weight: 600;
    margin-left: 24px;
    letter-spacing: 0.04em;
}

.phonenumber {
    margin-top: 10px;
    font-size: 28px;
    letter-spacing: 0.04em;
    font-weight: 600;
}

.timings {
    font-size: 16px;
    font-weight: 600;
    line-height: 116%;
    color: #464CDD;
    margin-top: 10px;
}

.mail {
    margin-top: 44px;
}

.mailhead {
    display: inline-flex;
}

.mailhead p {
    font-size: 24px;
    font-weight: 600;
    margin-left: 24px;
    letter-spacing: 0.04em;
}

.email {
    margin-top: 10px;
    font-size: 28px;
    letter-spacing: 0.04em;
    font-weight: 600;
}

.rightcontent {
    margin-left: 100px;
}

#gap {
    margin-bottom: 32px;
    border: 1px solid rgba(0, 22, 54, 0.92);
    border-radius: 16px;
    width: 440px;
    font-size: 20px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 56px;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
}

.select {
    border: 1px solid rgba(0, 22, 54, 0.92) !important;
    margin-bottom: 32px;
    border-radius: 16px;
    width: 440px;
    font-size: 20px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    height: 56px;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 14px;
}

.select:invalid {
    color: rgba(52, 52, 52, 0.64) !important;
}

#textarea {
    border: 1px solid rgba(0, 22, 54, 0.92);
    margin-bottom: 32px;
    border-radius: 16px;
    width: 440px;
    font-size: 20px;
    line-height: 116%;
    letter-spacing: 0.04em;
    color: #0F143A;
    text-decoration: none;
    padding-left: 24px;
    font-family: 'Nunito', sans-serif;
    align-items: center;
    height: 178px;
    padding-top: 20px;
}

#textarea::placeholder {
    color: rgba(52, 52, 52, 0.64)
}

.button {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.bttn {
    border: 1px solid rgba(0, 22, 54, 0.92);
    background: rgba(15, 20, 58, 0.08);
    cursor: pointer;
    width: 275px;
    padding: 20px 32px !important;
    height: 67px !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 136%;
    border-radius: 120px;
    margin-bottom: 28px;
    font-family: 'Nunito', sans-serif;
}

.bttn:hover{
    background: #0F143A;
    color: white;
}

@media only screen and (max-width: 1170px) {
    .chat {
        left: calc(50% - 230px);
    }

    .man {
        left: calc(50% - 441px);
    }

    .letter {
        left: calc(50% - 401px);
    }

    .headphones {
        left: calc(50% + 245px);
    }

    .mark {
        left: calc(50% + 403px);
    }

    .available {
        left: calc(50% + 330px);
    }

    .rightcontent {
        margin-left: 100px;
    }

    .mainform {
        width: 948px;
    }

    #gap{
        width: 360px;
    }

    .select {
        width: 360px;
    }

    #textarea{
        width: 360px;
    }
}

@media only screen and (max-width: 996px) {
    .chat {
        left: calc(50% - 230px);
    }

    .man {
        left: calc(50% - 400px);
        top: 174px;
    }

    .letter {
        left: calc(50% - 360px);
        top: 434px;
    }

    .headphones {
        left: calc(50% + 205px);
        top: 130px;
    }

    .mark {
        left: calc(50% + 353px);
        top: 255px;
    }

    .available {
        left: calc(50% + 280px);
        top: 418px;
    }

    .rightcontent {
        margin-left: 54px;
    }

    .mainform {
        width: 780px;
    }

    .leftcontent h4 {
        width: 155px;
        font-size: 24px;
    }

    .leftcontent h2 {
        font-size: 28px;
    }

    .leftcontent .call {
        margin-top: 48px;
    }

    .callhead p {
        font-size: 22px;
    }

    .phonenumber {
        font-size: 24px;
    }

    .mail {
        margin-top: 36px;
    }

    .mailhead p {
        font-size: 22px;
    }

    .email {
        font-size: 24px;
    }

    #gap {
        width: 360px;
        font-size: 18px !important;
    }

    .select {
        width: 360px;
        font-size: 18px !important;
    }

    #textarea {
        width: 360px;
        font-size: 18px;
    }

    .bttn {
        width: 243px;
    }
}

@media only screen and (max-width: 812px) {
    .contact{
        padding-top: 164px;
    }

    .contact h1 {
        font-size: 48px;
    }

    .contact h3 {
        font-size: 19px;
        width: 261px;
        margin-left: calc(50% - 130px);
        line-height: 120%;
        margin-top: 24px;
    }

    .chat {
        left: calc(50% - 162px);
        top: 120px;
    }

    .chat img {
        width: 60px;
        height: 48px;
    }

    .man {
        left: calc(50% - 219px);
        top: 263px;
    }

    .man img {
        width: 50px;
        height: 60px;
    }

    .letter {
        left: calc(50% - 248px);
        top: 408px;
    }

    .letter img {
        width: 60px;
        height: 60px;
    }

    .headphones {
        left: calc(50% + 126px);
        top: 124px;
    }

    .headphones img {
        width: 66px;
        height: 72px;
    }

    .mark {
        left: calc(50% + 197px);
        top: 278px;
    }

    .mark img {
        width: 36px;
        height: 40px;
    }

    .available {
        left: calc(50% + 206px);
        top: 380px;
    }

    .available img {
        width: 42px;
        height: 40px;
    }

    .faq {
        width: 428px;
        height: 192px;
    }

    .leftfaq {
        font-size: 18px;
        font-weight: 600;
        line-height: 136%;
        margin-top: 40px;
        margin-left: 24px;
    }

    .faqbutton {
        padding: 10px 24px;
        font-size: 18px;
        margin-top: 20px;
    }

    .imagefaq {
        margin-right: 40px;
        margin-top: 24px;
    }

    .imagefaq img {
        width: 84px;
        height: 140px;
    }

    .lower {
        display: flex;
        justify-content: center;
    }

    .mainform {
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        padding: 0px;
        border-radius: 32px;
        margin-top: 96px;
        margin-bottom: 120px;
        justify-content: center;
        width: 640px;
    }

    .leftcontent {
        text-align: center;
    }

    .leftcontent h4 {
        margin-top: 36px;
        width: 175px;
        margin-left: calc(50% - 87px);
        text-align: center;
        border-radius: 8px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 28px;
        line-height: 116%;
        font-weight: 700;
        margin-bottom: 36px;
    }

    .leftcontent h2 {
        display: inline;
        line-height: 136%;
        color: #0F143A;
        font-weight: 600;
        font-size: 24px;
        padding-right: 12px;
    }

    .callandmail{
        display: none;
    }

    .downcallandmail {
        display: flex;
        text-align: left;
        align-items: center;
        margin-bottom: 56px;
        margin-top: 36px;
    }

    .leftcontent .call {
        margin-top: 0px;
        margin: 0px;
        padding: 0px;
    }

    .callhead {
        display: inline-flex;
    }

    .callhead p {
        margin-left: 24px;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.04em;
    }

    .phonenumber {
        margin-top: 14px;
        font-size: 20px;
        letter-spacing: 0.04em;
        font-weight: 600;
    }

    .timings {
        font-size: 14px;
        font-weight: 600;
        line-height: 116%;
        margin-top: 12px;
    }

    .mail {
        margin-top: 0px;
        margin-left: 56px;
        display: block;
        float: left;
    }

    .mailhead {
        display: inline-flex;
    }

    .mailhead p {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.04em;
    }

    .email {
        margin-top: 14px;
        font-size: 20px;
        letter-spacing: 0.04em;
        font-weight: 600;
    }

    .rightcontent {
        margin-left: 0px;
        margin-top: 28px;
        text-align: center;
    }

    #gap {
        width: 360px;
        font-size: 18px;
    }
    
    .select {
        width: 360px;
        font-size: 18px;
        background-position-x: 96%;
    }
    
    #textarea {
        width: 360px;
        font-size: 18px;
    }

    .bttn {
        width: 243px;
        margin-bottom: 40px;
    }   
}

@media only screen and (max-width: 640px) {
    .contact{
        padding-top: 92px;
    }

    .contact h1 {
        font-size: 24px;
    }

    .contact h3 {
        font-size: 14px;
        width: 184px;
        margin-left: calc(50% - 92px);
        line-height: 120%;
        margin-top: 12px;
        letter-spacing: 00.04em;
        margin-bottom: 24px;
    }

    .chat {
        left: calc(50% - 98px);
        top: 64px;
    }

    .chat img {
        width: 40px;
        height: 32px;
    }

    .man {
        left: calc(50% - 128px);
        top: 158px;
    }

    .man img {
        width: 30px;
        height: 36px;
    }

    .letter {
        left: calc(50% - 178px);
        top: 260px;
    }

    .letter img {
        width: 36px;
        height: 36px;
    }

    .headphones {
        left: calc(50% + 78px);
        top: 66px;
    }

    .headphones img {
        width: 44px;
        height: 48px;
    }

    .mark {
        left: calc(50% + 105px);
        top: 166px;
    }

    .mark img {
        width: 21px;
        height: 24px;
    }

    .available {
        left: calc(50% + 149px);
        top: 262px;
    }

    .available img {
        width: 21px;
        height: 20px;
    }

    .faq {
        width: 318px;
        height: 144px;
    }

    .leftfaq {
        font-size: 16px;
        margin-top: 16px;
    }

    .faqbutton {
        padding: 8px 14px;
        font-size: 12px;
        margin-top: 12px;
    }

    .imagefaq {
        margin-right: 20px;
        margin-top: 24px;
    }

    .imagefaq img {
        width: 58px;
        height: 96px;
    }

    .lower {
        display: flex;
        justify-content: center;
    }

    .mainform {
        display: flex;
        flex-wrap: wrap;
        background-color: white;
        padding: 0px;
        border-radius: 32px;
        margin-top: 48px;
        margin-bottom: 56px;
        justify-content: center;
        width: 348px;
    }

    .leftcontent {
        text-align: center;
    }

    .leftcontent h4 {
        margin-top: 32px;
        width: 124px;
        margin-left: calc(50% - 62px);
        text-align: center;
        border-radius: 8px;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 18px;
        line-height: 116%;
        font-weight: 700;
        margin-bottom: 23px;
    }

    .leftcontent h2 {
        display: inline;
        line-height: 136%;
        color: #0F143A;
        font-weight: 600;
        font-size: 20px;
        padding-right: 12px;
    }

    .callandmail{
        display: none;
    }

    .downcallandmail {
        display: flex;
        text-align: left;
        align-items: center;
        margin-bottom: 44px;
    }

    .leftcontent .call {
        margin-top: 0px;
        margin: 0px;
        padding: 0px;
    }

    .callhead {
        display: inline-flex;
    }

    .callhead img{
        width: 12px;
        height: 14px;
        padding-top: 2px;
    }

    .callhead p {
        margin-left: 14px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.04em;
    }

    .phonenumber {
        margin-top: 12px;
        font-size: 14px;
        letter-spacing: 0.04em;
        font-weight: 600;
    }

    .timings {
        font-size: 10px;
        font-weight: 600;
        line-height: 116%;
        margin-top: 8px;
    }

    .mail {
        margin-top: 0px;
        margin-left: 40px;
        display: block;
        float: left;
    }

    .mailhead {
        display: inline-flex;
    }

    .mailhead img{
        width: 16px;
        height: 16px;
    }

    .mailhead p {
        font-size: 14px;
        margin-left: 14px;
        font-weight: 600;
        letter-spacing: 0.04em;
    }

    .email {
        margin-top: 12px;
        font-size: 14px;
        letter-spacing: 0.04em;
        font-weight: 600;
    }

    .rightcontent {
        margin-left: 0px;
        margin-top: 24px;
        text-align: center;
    }

    #gap {
        width: 300px;
        font-size: 16px !important;
        padding-left: 18px;
        margin-bottom: 24px;
    }
    
    .select {
        width: 300px;
        font-size: 16px !important;
        background-position-x: 97%;
        background-position-y: 16px;
        padding-left: 18px;
        margin-bottom: 24px;
    }
    
    #textarea {
        width: 300px;
        font-size: 16px !important;
        padding-left: 18px;
        margin-bottom: 32px;
    }

    .bttn {
        width: 199px;
        padding: 14px 28px !important;
        height: 50px !important;
        font-size: 16px;
        margin-bottom: 40px;
    }
}