@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600");
@import url("https://fonts.googleapis.com/css2?family=Gamja+Flower&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.container {
    font-family: "Nunito", sans-serif;
    padding-top: 144px;
    text-align: center;
    background: #FAF7F1;
    justify-content: center;
}

.container h1 {
    display: inline-flex;
    text-align: center;
    align-items: center;
    position: relative;
    font-weight: 700;
    font-size: 50px;
    line-height: 116%;
    margin-bottom: 24px;
}

.blue {
    color: #4480ff;
}

.swagcircle {
    position: absolute;
    margin-left: -12px;
    margin-top: -10px;
    width: 165px;
    height: 80px;
}

.swagfor {
    padding-left: 8px;
    padding-right: 15px;
}

.container p {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: rgba(52, 52, 52, 0.64);
    letter-spacing: 0.04em;
}

.startbtn {
    padding: 16px 40px;
    width: 215px;
    height: 62px;
    background: #0F143A;
    border-radius: 6px;
    text-align: center;
    align-items: center;
    color: white;
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    line-height: 124%;
    letter-spacing: 0.016em;
    margin-top: 42px;
    margin-bottom: 56px;
    cursor: pointer;
    border: none;
}

.blue {
    color: #2A32FF;
}

.hoodieman {
    padding-bottom: 52px;
}

.hoodieman img {
    width: 528px;
    height: 360px;
}

.teesandbottle {
    position: absolute;
    display: inline-flex;
    margin-top: 20px;
}

.tees {
    margin-left: -450px;
    position: absolute;
    animation: mockups 1.2s linear infinite alternate;
}

.bottle {
    position: absolute;
    margin-top: 30px;
    margin-left: 100px;
    animation: mockups 1.2s linear infinite alternate;
}

.socksandnotebook {
    position: absolute;
    display: inline-flex;
    margin-top: -300px;
}

.socks {
    position: absolute;
    margin-left: -520px;
    animation: mockups 1.2s linear infinite alternate;
}

.notebook {
    position: absolute;
    margin-left: 300px;
    animation: mockups 1.2s linear infinite alternate;
}

@keyframes mockups {
    from {
        transform: translateY(+0.3em);
    }

    to {
        transform: translateY(+1.0em);
    }
}

@media only screen and (max-width:1240px) {
    .hoodieman img {
        width: 410px;
        height: 274px;
    }

    .teesandbottle {
        margin-top: 50px;
    }

    .tees {
        margin-left: -450px;
    }

    .tees img {
        width: 144px;
        height: 152px;
    }

    .bottle {
        margin-top: 8px;
        margin-left: 100px;
    }

    .bottle img {
        width: 114px;
        height: 120px;
    }

    .socksandnotebook {
        margin-top: -260px;
    }

    .socks {
        margin-top: 40px;
        margin-left: -420px;
    }

    .socks img {
        width: 164px;
        height: 122px;
    }

    .notebook {
        margin-left: 260px;
    }

    .notebook img {
        width: 165px;
        height: 165px;
    }
}

@media only screen and (max-width:990px) {
    .container h1 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .swagcircle {
        position: absolute;
        margin-left: -10px;
        margin-top: -10px;
        width: 137px;
        height: 70px;
    }

    .container p {
        font-size: 18px;
    }

    .startbtn {
        margin-top: 44px;
        margin-bottom: 42px;
    }

    .hoodieman img {
        width: 330px;
        height: 215px;
    }

    .teesandbottle {
        margin-top: 50px;
    }

    .tees {
        margin-left: -380px;
    }

    .tees img {
        width: 106px;
        height: 112px;
    }

    .bottle {
        margin-top: 8px;
        margin-left: 50px;
    }

    .bottle img {
        width: 84px;
        height: 88px;
    }

    .socksandnotebook {
        margin-top: -260px;
    }

    .socks {
        margin-top: 40px;
        margin-left: -320px;
    }

    .socks img {
        width: 131px;
        height: 131px;
    }

    .notebook {
        margin-left: 210px;
    }

    .notebook img {
        width: 165px;
        height: 165px;
    }
}

@media only screen and (max-width:800px) {
    .container h1 {
        font-size: 32px;
        margin-bottom: 20px;
    }

    .swagcircle {
        position: absolute;
        margin-left: -8px;
        margin-top: -10px;
        width: 110px;
        height: 54px;
    }

    .container p {
        font-size: 18px;
    }

    .startbtn {
        margin-top: 40px;
        margin-bottom: 42px;
    }

    .hoodieman img {
        width: 300px;
        height: 197px;
    }

    .teesandbottle {
        margin-top: 50px;
    }

    .tees {
        margin-left: -340px;
    }

    .tees img {
        width: 96px;
        height: 100px;
    }

    .bottle {
        margin-top: 8px;
        margin-left: 30px;
    }

    .bottle img {
        width: 84px;
        height: 88px;
    }

    .socksandnotebook {
        margin-top: -220px;
    }

    .socks {
        margin-top: 20px;
        margin-left: -250px;
    }

    .socks img {
        width: 118px;
        height: 88px;
    }

    .notebook {
        margin-left: 150px;
    }

    .notebook img {
        width: 103px;
        height: 103px;
    }
}

@media only screen and (max-width:640px) {
    .container {
        padding-top: 84px;
    }

    .container h1 {
        font-size: 18px;
        margin-bottom: 12px;
        letter-spacing: -0.04em;
    }

    .swagcircle {
        position: absolute;
        margin-left: -5px;
        margin-top: -5px;
        width: 60px;
        height: 30px;
    }

    .swagfor {
        padding-right: 10px;
    }

    .container p {
        width: 220px;
        font-size: 14px;
        margin-left: calc(50% - 110px);
    }

    .startbtn {
        padding: 10px 24px;
        width: 148px;
        height: 42px;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: 0.01em;
        margin-top: 20px;
        margin-bottom: 134px;
    }

    .hoodieman {
        padding-bottom: 28px;
    }

    .hoodieman img {
        width: 322px;
        height: 220px;
    }

    .teesandbottle {
        margin-top: 76px;
    }

    .tees {
        margin-left: -170px;
    }

    .tees img {
        width: 80px;
        height: 84px;
    }

    .bottle {
        margin-top: 4px;
        margin-left: -40px;
    }

    .bottle img {
        width: 68px;
        height: 72px;
    }

    .socksandnotebook {
        margin-top: -220px;
    }

    .socks {
        margin-top: -74px;
        margin-left: -170px;
    }

    .socks img {
        width: 110px;
        height: 76px;
    }

    .notebook {
        margin-left: 84px;
        margin-top: -90px;
    }

    .notebook img {
        width: 90px;
        height: 96px;
    }
}